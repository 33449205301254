var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-message-request-setting"},[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key':'message',
        'prefix': 'integration_',
        'buttonsHelpView': _vm.$t('select_editor_button_help_message'),
        'validation': 'required',
        'availableButtonsTypes': _vm.newPmButtons,
        'has-media': true,
        'base-api-url': 'api.presscode.app/cabinet',
        'targetToUpload': {
          'target': 'target',
          'target_id': _vm.$store.state.boardsState.activeBoard.board
        },
        'settingsStructure': {
          'remove_after': 0,
          'pin': false,
          'remove_previous': true,
          'disable_link_preview': false,
          'disable_notify': false,
          'protect_content': false,
        }
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }